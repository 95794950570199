<template>
	<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	>
		<div class="rounded-t bg-white mb-0 px-6 py-6">
			<div class="text-center flex justify-between">
				<h6 class="text-blueGray-700 text-xl font-bold">Galerija slika</h6>
				<div class="bg-gray-200 text-center py-3 px-4 rounded-md">
          <input type="file" class="form-file" id="fileInput" accept="image/*" ref="fileInput" @change="uploadFile"/>
          <label for="fileInput" class="text-blue-500 hover:text-blue-600 cursor-pointer">Dodaj novu sliku</label>
        </div>
			</div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
			<form>
				<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>
				<div class="flex flex-wrap">
					<div class="flex flex-wrap">
						<div
              class="bg-white rounded-lg w-1/3 max-w-md m-4"
              v-for="image in gallery" :key="image.galleryID">
							<img :src="image.url" class="rounded-lg w-1/3" />
							<div class="p-6">
								<div class="flex justify-between mt-6">
                  <button
                    @click.stop.prevent="setActive(image.galleryID)"
                    class="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded-md flex items-center">
                    <i v-if="image.isApproved" class="fas fa-check mr-2"></i>
                    Aktivna
                  </button>
                  <button @click.stop.prevent="deleteImage(image.galleryID)" class="bg-red-600 text-white px-4 py-2 rounded-lg">
                    Briši
                  </button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
	name: 'GalleryImages',
	data() {
		return {
		};
	},
	components: {
	},
	props: {
	},

	computed: {
		gallery() {
			return this.$store.getters.gallery || [];
		},	
	},

  mounted() {
    this.getGallery();
  },

	methods: {
    setActive(id) {
      this.$store.dispatch('activeGalleryPhoto', id).then(() => {
        this.getGallery();
      });
    },
    deleteImage(id) {
      this.$store.dispatch('deleteGalleryPhoto', id).then(() => {
        this.getGallery();
      });
    },
    getGallery() {
      this.$store.dispatch('getGallery');
    },

    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      this.$store.dispatch('uploadGalleryImage', file).then(() => {
        this.getGallery();
        this.$refs.fileInput.value = '';
      });
    },
	},
};
</script>
  