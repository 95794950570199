const getters = {
  currentUser:state=>state.currentUser,
  tours: state => state.tours,
  blogs: state => state.blogs,
  currentTour: state => state.currentTour,
  currentBlog: state => state.currentBlog,
  tourCategory: state => state.tourCategories || [],
  foodPackages: state => state.foodPackages || [],
  activityLevels: state => state.activityLevels|| [],
  activities: state => state.activities || [],
  gallery: state => state.galleryPhotos || [],
  getAddingTour: state => state.addingTour,
  getLoading: state => state.loading,
};

export default getters;