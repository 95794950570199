import { createApp } from "vue";
import store from './store';
import router from './router';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { useToast } from "vue-toastification";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toast);
app.mount('#app');

app.config.globalProperties.$toast = useToast();
