<template>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <blogs-table />
      </div>
    </div>
  </template>
  <script>
  import BlogsTable from "@/components/Tables/BlogsTable.vue";
  
  export default {
    name: 'Blogs',
    components: {
        BlogsTable,
    },
  };
  </script>
  