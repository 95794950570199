<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
        <footer-admin />
        <LoadingSpiner v-if="loading" />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import LoadingSpiner from "@/components/Widgets/LoadingSpiner.vue";

export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
    LoadingSpiner,
  },

  mounted() {
    this.$store.dispatch('getTours');
    this.$store.dispatch('getBlogs');
    this.$store.dispatch('getFoodPackages');
    this.$store.dispatch('getActivityLevels');
    this.$store.dispatch('getActivities');
    this.$store.dispatch('getGallery');
  },

  computed: {
    loading() {
      return this.$store.getters.getLoading;
    }
  }
};
</script>
