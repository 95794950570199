<template>
	<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	>
		<div class="rounded-t bg-white mb-0 px-6 py-6">
			<div class="text-center flex justify-between">
				<h6 class="text-blueGray-700 text-xl font-bold">Plan Aktivnosti</h6>
			</div>
		</div>
		<div class="flex-auto px-2 lg:px-1 py-10 pt-0">
			<form>
				<div class="flex flex-wrap">
					<div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Dan
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="plan.day"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Sat
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="plan.hour"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Naziv
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="plan.name"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Naziv EN
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="plan.nameEn"
              />
            </div>
            <div class="relative w-full mb-3">
              <button
                class="bg-emerald-800 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                @click="addNewPlan"
              >
                Dodaj
              </button>
            </div>
					</div>
          <div class="w-full lg:w-8/12 px-4">
            <div
              class="flex flex-col border-t py-2 my-2"
              v-for="item in activities" :key="item.planAktivnostiId">
              <div class="font-bold mr-1">Dan {{ item.rbDan }}.</div>
              <div class="mr-1">{{ item.satnica }}h</div>
              <div class="mr-1">{{ item.naziv }}</div>
              <button
                @click="removePlan(item.planAktivnostiId)"
                class="bg-red-500 w-16 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Brisi
              </button>
            </div>
          </div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
  name: 'TourPlan',
	data() {
    return {
      plan: {},
		};
	},
	components: {
    
	},
	props: {
	},

	computed: {
		currentTour() {
			return this.$store.getters.currentTour || {};
		},
		activities() {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			return this.tourPlan.sort((a, b) => {
        if (a.rbDan === b.rbDan) {
          return a.hour > b.hour ? 1 : -1;
        }
        return a.rbDan > b.rbDan ? 1 : -1;
      });
		},
		tourPlan() {
      return this.currentTour.planAktivnosti.map(obj => {
        if (obj.rbDan && obj.satnica) {
          obj.rbDan = Number(obj.rbDan);
          obj.hour = Number(obj.satnica.split(':')[0]);
        }
				return obj;
			});
		},	
	},

	methods: {
    addNewPlan() {
      const payload = {
        DatumAktivnosti: new Date(),
        RbDan: this.plan.day,
        Satnica: this.plan.hour,
        Naziv: this.plan.name,
        NazivEn: this.plan.nameEn,
        tourId: this.currentTour.tourId,
      };
      this.$store.dispatch('addTourPlan', payload).then(() => {
        this.plan = {};
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
    removePlan(id) {
      this.$store.dispatch('deleteTourPlan', id).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
	},
};
</script>
  