<template>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <GalleryImages />
      </div>
    </div>
  </template>
  <script>
  import GalleryImages from "@/components/Widgets/GalleryImages.vue";
  
  export default {
    components: {
        GalleryImages,
    },
  };
  </script>