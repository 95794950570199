<template>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <tours-table />
      </div>
    </div>
  </template>
  <script>
  import ToursTable from "@/components/Tables/ToursTable.vue";
  
  export default {
    components: {
      ToursTable,
    },

    mounted() {
      this.$store.dispatch('getTours');
    },
  };
  </script>
  