const getDefaultState = {
    tours: [],
    currentTour: {},
    currentBlog: {},
    blogs: [],
    currentUser: {},
    activityLevels: [],
    foodPackages: [],
    tourCategories: [
      {
        id: 1,
        name: 'Jednodevna tura',
        nameEn: 'One-day offer',
      },
      {
        id: 2,
        name: 'Višednevna tura',
        nameEn: 'Multy-day offer',
      },
    ],
    activities: [],
    galleryPhotos: [],
    addingTour: false,
    loading: false,
};

export default getDefaultState;