import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import getDefaultState from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default createStore({
    namespaced: true,
    state: getDefaultState,
    actions,
    mutations,
    getters,
    plugins: [createPersistedState()]
  })