<template>
	<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	>
		<div class="rounded-t bg-white mb-0 px-6 py-6">
			<div class="text-center flex justify-between">
				<h6 class="text-blueGray-700 text-xl font-bold">{{ currentTour.name }}</h6>
				<button
					class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
					type="button"
          @click="save"
				>
					Spremi
				</button>
			</div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
			<form>
				<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
					Aktivnosti ture
				</h6>
				<div class="flex flex-wrap">
					<div class="w-full lg:w-12/12 px-4">
						<div v-for="activity in activities" :key="activity.activityId">
							<input id="recomended" type="checkbox" v-model="activity.selected" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">{{ activity.name }}</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
	name: 'TourActivity',
	data() {
		return {
		};
	},
	components: {
	},
	props: {
	},

	computed: {
		currentTour() {
			return this.$store.getters.currentTour || {};
		},
		activities() {
			return this.$store.getters.activities.map(obj => {
				if (this.tourActivities.some(val => val.activityId === obj.activityId)) {
					obj.selected = true;
				} else {
					obj.selected = false;
				}
				return obj;
			});
		},
		tourActivities() {
			return this.currentTour.tourActivity || [];
		},	
	},

	methods: {
    save() {
      const activityIds = this.activities
        .filter(item => item.selected === true)
        .map(item => item.activityId);

      this.$store.dispatch('updateTourActivities', {
        tourId: this.currentTour.tourId,
        activityIds,
      }).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    }
	}
};
</script>
  