<template>
    <div class="loader">
      <div class="loader-circle"></div>
    </div>
  </template>
  
  <style scoped>
  .loader {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader-circle {
    border: 4px solid #38b2ac;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  