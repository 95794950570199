<template>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <TourDetails />
      </div>
    </div>
  </template>
  <script>
  import TourDetails from "@/components/Cards/TourDetails.vue";
  
  export default {
    components: {
        TourDetails,
    },
  };
  </script>