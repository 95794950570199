<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
  <div class="flex flex-wrap">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 py-4 flex-row" v-if="!addingTour"> 
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-lightBlue-600 bg-white': openTab !== 1, 'text-white bg-lightBlue-600': openTab === 1}">
            Detalji
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="!addingTour">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-lightBlue-600 bg-white': openTab !== 2, 'text-white bg-lightBlue-600': openTab === 2}">
            Plan ture
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="!addingTour">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-lightBlue-600 bg-white': openTab !== 3, 'text-white bg-lightBlue-600': openTab === 3}">
            Aktivnosti
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="!addingTour">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-lightBlue-600 bg-white': openTab !== 4, 'text-white bg-lightBlue-600': openTab === 4}">
            Slike
          </a>
        </li>
      </ul>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
              <AddEditTourDetails :tour="tour" />
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <TourPlan />
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <TourActivity />
            </div>
            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
              <TourImages />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import AddEditTourDetails from "@/components/Cards/AddEditTourDetails.vue";
import TourActivity from "@/components/Widgets/TourActivity.vue";
import TourImages from "@/components/Widgets/TourImages.vue";
import TourPlan from "@/components/Widgets/TourPlan.vue";

export default {
  name: "tour-details",

  components: {
    AddEditTourDetails,
    TourActivity,
    TourImages,
    TourPlan,
  },
  data() {
    return {
      openTab: 1,
    }
  },
  
  computed: {
    tour() {
      return this.$store.getters.currentTour || {};
    },
    addingTour() {
      return this.$route.params?.id === 'newtour';
    },
  },  
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
  },
  destroyed() {
    console.log('destroyed');
  },
}
</script>