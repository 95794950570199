<template>
	<div>
		<div v-if="editor">
			<button
				@click.stop.prevent="editor.chain().focus().toggleBold().run()"
				:disabled="!editor.can().chain().focus().toggleBold().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('bold'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-bold"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleItalic().run()"
				:disabled="!editor.can().chain().focus().toggleItalic().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('italic'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-italic"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleStrike().run()"
				:disabled="!editor.can().chain().focus().toggleStrike().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('strike'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-strikethrough"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 1 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 1 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H1</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 2 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 2 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H2</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 3 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 3 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 3 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H3</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 4 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 4 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H4</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 5 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 5 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 5 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H5</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleHeading({ level: 6 }).run()"
				:disabled="!editor.can().chain().focus().toggleHeading({ level: 6 }).run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('heading', { level: 6 }), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<strong>H6</strong>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleBulletList().run()"
				:disabled="!editor.can().chain().focus().toggleBulletList().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('bulletList'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-list"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleOrderedList().run()"
				:disabled="!editor.can().chain().focus().toggleOrderedList().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('orderedList'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-list-ol"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleCodeBlock().run()"
				:disabled="!editor.can().chain().focus().toggleCodeBlock().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('codeBlock'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-code"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().toggleBlockquote().run()"
				:disabled="!editor.can().chain().focus().toggleBlockquote().run()"
				:class="{ 'is-active bg-salate-300': editor.isActive('blockquote'), 'border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1': true }"
				>
				<i class="fas fa-block_quote"></i>BQ
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().setHorizontalRule().run()"
				:disabled="!editor.can().chain().focus().setHorizontalRule().run()"
				class="border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1"
				>
				<i class="fas fa-grip-lines"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().undo().run()"
				:disabled="!editor.can().chain().focus().undo().run()"
				class="border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1"
				>
				<i class="fas fa-arrow-left"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().redo().run()"
				:disabled="!editor.can().chain().focus().redo().run()"
				class="border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1"
				>
				<i class="fas fa-arrow-right"></i>
			</button>
			<button
				@click.stop.prevent="editor.chain().focus().image().run()"
				class="border-2 border-solid border-black bg-white text-black font-medium py-2 px-4 rounded-md mr-1 mb-1">
				<i class="fas fa-image"></i>
				<!-- <input type="file" ref="fileInput" @change.stop.prevent="handleFileUpload" style="display: none;"/> -->
			</button>
			<!-- <button @click="$refs.fileInput.click()">Upload Image</button> -->
		</div>
		<editor-content
			ref="editor"
			:editor="editor"
			@paste="handlePaste"
			/>
	</div>
</template>
  
<script>
  import StarterKit from '@tiptap/starter-kit'
  import { Editor, EditorContent } from '@tiptap/vue-3'
  
  export default {
    components: {
      EditorContent,
    },
  
    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },
  
    emits: ['update:modelValue'],
  
    data() {
      return {
        editor: null,
      }
    },
  
    watch: {
      modelValue(value) {
        // HTML
        const isSame = this.editor.getHTML() === value
  
        // JSON
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
  
        if (isSame) {
          return
        }
  
        this.editor.commands.setContent(value, false)
      },
    },

		methods: {
			insertImage() {
      this.$refs.fileInput.click()
    },
			handleFileUpload(e) {
				let file = e.target.files[0];
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (e) => {
					this.editor.commands.setContent('image', {
						src: e.target.result,
						alt: file.name,
					});
				}
			},
			handlePaste(e) {
				let clipboardData = e.clipboardData;
				if (!clipboardData || !clipboardData.types.length) {
					return;
				}
				if (clipboardData.types.includes('Files')) {
					let file = clipboardData.items[0].getAsFile();
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = (e) => {
						console.log(this.$refs.editor);
						this.editor.commands.setContent('image', {
							src: e.target.result,
							alt: file.name,
						});
					}
				}
			},
		},
  
    mounted() {
      this.editor = new Editor({
        extensions: [
          StarterKit,
        ],
				editorProps: {
					attributes: {
						class: 'bg-white rounded-lg mt-2 px-4 py-4 prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none h-screen overflow-y-auto',
					},
				},
        content: this.modelValue,
        onUpdate: () => {
          // HTML
          this.$emit('update:modelValue', this.editor.getHTML())
  
          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
      })
    },
  
    beforeUnmount() {
      this.editor.destroy()
    },
  }
</script>

<style>
/* Basic editor styles */
.ProseMirror > * + * {
    margin-top: 0.75em;
		background: #FFF;
}

.ProseMirror p {

}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.ProseMirror pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}

</style>