<template>
	<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	>
		<div class="rounded-t bg-white mb-0 px-6 py-6">
			<div class="text-center flex justify-between">
				<h6 class="text-blueGray-700 text-xl font-bold">{{ currentTour.name }}</h6>
				<div class="bg-gray-200 text-center py-3 px-4 rounded-md">
          <input type="file" class="form-file" id="fileInput" accept="image/*" ref="fileInput" @change="uploadFile"/>
          <label for="fileInput" class="text-blue-500 hover:text-blue-600 cursor-pointer">Dodaj novu sliku</label>
        </div>
			</div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
			<form>
				<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
					Slike ture
				</h6>
				<div class="flex flex-wrap">
					<div class="flex flex-wrap">
						<div
              class="bg-white rounded-lg w-1/3 max-w-md m-4"
              v-for="image in tourImages" :key="image.id">
							<img :src="image.url" class="rounded-lg w-1/3" />
							<div class="p-6">
								<div class="flex justify-between mt-6">
                  <button
                    @click.stop.prevent="setSlider(image.id)"
                    class="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded-md flex items-center">
                    <i v-if="image.forSlider" class="fas fa-check mr-2"></i>
                    Slider
                  </button>
                  <button
                    @click.stop.prevent="setMain(image.id)"
                    class="bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 px-4 rounded-md flex items-center">
                    <i v-if="image.forSlider" class="fas fa-check mr-2"></i>
                    Main
                  </button>
                  <button
                    @click.stop.prevent="setThumb(image.id)"
                    class="bg-emerald-500 hover:bg-emerald-600 text-white font-medium py-2 px-4 rounded-md flex items-center">
                    <i v-if="image.forSlider" class="fas fa-check mr-2"></i>
                    Thumb
                  </button>
									<button @click.stop.prevent="deleteImage(image.id)" class="bg-red-600 text-white px-4 py-2 rounded-lg">Delete</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>

export default {
	name: 'TourImages',
	data() {
		return {
		};
	},
	components: {
	},
	props: {
	},

	computed: {
		currentTour() {
			return this.$store.getters.currentTour || {};
		},
		tourImages() {
			return this.currentTour.tourImages || [];
		},
		activities() {
			return this.$store.getters.activities.map(obj => {
				if (this.tourActivities.some(val => val.activityId === obj.activityId)) {
					obj.selected = true;
				} else {
					obj.selected = false;
				}
				return obj;
			});
		},
		tourActivities() {
			return this.currentTour.tourActivity || [];
		},	
	},

	methods: {
    setSlider(id) {
      this.$store.dispatch('setSliderPhoto', { tourId: this.currentTour.tourId, id}).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
    setMain(id) {
      this.$store.dispatch('setMainPhoto', { tourId: this.currentTour.tourId, id}).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
    setThumb(id) {
      this.$store.dispatch('setThumbPhoto', { tourId: this.currentTour.tourId, id}).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
    deleteImage(id) {
      this.$store.dispatch('deletePhoto', { tourId: this.currentTour.tourId, id}).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
      });
    },
    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      this.$store.dispatch('uploadTourImage', { tourId: this.currentTour.tourId, file }).then(() => {
        this.$store.dispatch('getTourById', this.currentTour.tourId);
        this.$refs.fileInput.value = '';
      });
    }
	}
};
</script>
  