<template>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <NewEditBlog />
      </div>
    </div>
  </template>
  <script>
  import NewEditBlog from "@/components/Cards/NewEditBlog.vue";
  
  export default {
    components: {
        NewEditBlog,
    },
  };
  </script>